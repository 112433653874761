import React, { useState } from 'react';
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery, Link } from 'gatsby'
import PrimaryTestimonial from '../components/primaryTestimonial';

const PricingPage = () => {
  const [isMonthlyView, changeMonthlyView] = useState(true);
  const data = useStaticQuery(graphql`
        {
          file(relativePath: { eq: "maegan-s.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          site {
            siteMetadata {
              pages {
                pricing {
                  title
                  description
                  url
                }
              }
            }
          }
        }
      `);

    const renderPricingTables = () => {
      return(
        <>
        <div className="pb-12 mt-16 bg-white lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 bg-gray-900 h-5/6 lg:h-2/3"></div>
            <div className="px-4 mx-auto sm:px-6 lg:px-8" style={{maxWidth: '90rem'}}>
              <div className="relative lg:grid lg:grid-cols-12">
                <div className="max-w-md mx-auto lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-5 lg:row-start-1 lg:row-end-4">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg">
                    <div className="flex flex-col flex-1">
                      <div className="px-6 pt-10 pb-0 bg-white">
                        <div>
                          <h3 className="text-2xl font-medium text-center text-gray-900" id="tier-hobby">
                            Startup
                          </h3>
                          <div className="flex items-center justify-center mt-4">
                            <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                
                              </span>
                              <span className="font-extrabold">
                                Free
                              </span>
                            </span>
                            {/* <span className="text-xl font-medium text-gray-500">
                              /month
                            </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 p-6 bg-white sm:p-10 lg:p-2 xl:p-3">
                        <span className="flex justify-center text-base font-light text-gray-500">
                        +10% of sales created by Batch
                        </span>
                      </div>
                      <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                        <ul className="space-y-4">
                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Lightning fast checkout
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Capture unlimited offers
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Convert offers into sales
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-400">
                              Upgrade to customize checkout
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-400">
                              Upgrade to hide branding 
                            </p>
                          </li>
                        </ul>
                        <div className="rounded-md shadow">
                          <Link to="/start" className="flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50" aria-describedby="tier-standard">
                            Get started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-lg mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-5 lg:col-end-9 lg:row-start-1 lg:row-end-4">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div className="absolute inset-0 border-4 border-indigo-600 rounded-lg pointer-events-none" aria-hidden="true"></div>
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider text-white uppercase bg-indigo-600 rounded-full">
                          Most popular
                        </span>
                      </div>
                    </div>
                    <div className="px-6 pt-12 pb-0 bg-white rounded-t-lg">
                      <div>
                        <h3 className="text-3xl font-semibold text-center text-gray-900 sm:-mx-6" id="tier-growth">
                          Growth
                        </h3>
                        <div className="flex items-center justify-center mt-4">
                          <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">
                              49
                            </span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">
                            /month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 px-6 pb-6 bg-white sm:pb-10 sm:px-10 lg:pb-2 lg:px-2 xl:pb-3 xl:px-3">
                        <span className="flex justify-center text-base font-light text-gray-500">
                        +8% of sales created by Batch
                        </span>
                      </div>
                    <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                      <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            Lightning fast checkout
                          </p>
                        </li>

                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            Capture unlimited offers
                          </p>
                        </li>

                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            Convert offers into sales
                          </p>
                        </li>

                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                          <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            Customize the checkout
                          </p>
                        </li>

                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                          <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            Hide Batch branding
                          </p>
                        </li>
                      </ul>
                      <div className="rounded-md shadow">
                        <Link to={`/start`} className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-batch-peach-dark" aria-describedby="tier-standard">
                            Get started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-md mx-auto mt-10 lg:m-0 lg:max-w-none lg:col-start-9 lg:col-end-13 lg:row-start-2 lg:row-end-3">
                  <div className="flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg">
                      <div className="flex flex-col flex-1">
                        <div className="px-6 pt-10 pb-0 bg-white">
                          <div>
                            <h3 className="text-2xl font-medium text-center text-gray-900" id="tier-scale">
                              Pro
                            </h3>
                            <div className="flex items-center justify-center mt-4">
                              <span className="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                                <span className="mt-2 mr-2 text-4xl font-medium">
                                  $
                                </span>
                                <span className="font-extrabold">
                                  99
                                </span>
                              </span>
                              <span className="text-xl font-medium text-gray-500">
                                /month
                              </span>
                            </div>
                          </div>
                        </div>
                      <div className="flex flex-col flex-1 p-6 bg-white sm:p-10 lg:p-2 xl:p-3">
                        <span className="flex justify-center text-base font-light text-gray-500">
                        +4% of sales created by Batch
                        </span>
                      </div>
                      <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                        <ul className="space-y-4">
                        <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Lightning fast checkout
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Capture unlimited offers
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Convert offers into sales
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                            <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Customize the checkout
                            </p>
                          </li>

                          <li className="flex items-start">
                            <div className="flex-shrink-0">
                            <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              Hide Batch branding
                            </p>
                          </li>
                        </ul>
                        <div className="rounded-md shadow">
                          <Link to={`/start`} className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-batch-peach-dark" aria-describedby="tier-standard">
                              Get started
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )
    }

    const renderBenefits = () => {
      return(
        <div className="container py-10 mx-auto mt-8 xl:grid xl:grid-cols-3 xl:gap-x-8">
            <div className="px-2">
              <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">Everything you need</h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900">All-in-one platform</p>
              <p className="mt-4 text-lg text-gray-500">Batch is a next-generation Shopify app that helps you offer the ideal discount to each customer and sell out any inventory instantly.</p>
            </div>
            <div className="px-2 mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
              <ul className="divide-y divide-gray-200">
                <li className="flex py-4 md:py-0 md:pb-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Decreases cart abandonment</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Increases adds to cart</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Increases initiated checkouts</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Increases conversions and sales</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Instantly clears out any inventory you want to sell</span>
                </li>
              </ul>
              <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                <li className="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Increases cashflow and sell-through</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Increases product turnover and volume</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Sells slow-moving products in minutes</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">Amplifies abandonment &amp; retargeting campaigns</span>
                </li>

                <li className="flex py-4">
                  <svg className="flex-shrink-0 w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3 text-base text-gray-500">...and so much more!</span>
                </li>
              </ul>
            </div>
          </div>
      )
    }

    const renderProductHuntOffer = () => {
      if (typeof document !== `undefined`) {
        if (window.location.href.indexOf("producthunt") !== -1) {
          return (
            <div className="relative px-4 mx-auto mt-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-5">
              <div className="max-w-md mx-auto lg:max-w-5xl">
                <div className="px-6 py-8 bg-orange-400 rounded-lg sm:p-10 lg:flex lg:items-center">
                  <div className="flex-1">
                    <div>
                      <h3 className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full">
                        Hey ProductHunter! <span role={'img'} aria-label={'wave'}>👋</span>
                      </h3>
                    </div>
                    <div className="mt-4 text-lg text-white">Thanks for checking out Batch for Shopify. Until June 1st 2021, ProductHunt members save 35% on the Yearly Plan <strong>(grandfathered value: $228/year)</strong>.</div>
                  </div>
                  <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <Link to="/install?producthunt" className="flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50">
                      Get Batch Yearly — $149/year
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="relative px-4 mx-auto mt-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-5">
              <div className="max-w-md mx-auto lg:max-w-5xl">
                <div className="px-6 py-8 bg-gray-100 rounded-lg sm:p-10 lg:flex lg:items-center">
                  <div className="flex-1">
                    <div>
                      <h3 className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full">
                        Personalized onboarding
                      </h3>
                    </div>
                    <div className="mt-4 text-lg text-gray-600">Want to see how it works before signing up? We'll show you how to sell out-of-stock in seconds.</div>
                  </div>
                  <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <Link to="/request-demo" className="flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50">
                    Request demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }
    }

    const renderFAQ = () => {
      return(
        <div className="bg-white">
          <div className="px-4 py-10 mx-auto max-w-7xl sm:py-12 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-center text-gray-900">
              Frequently asked questions
            </h2>
            <div className="mt-12">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Does this replace my regular checkout?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Not at all. Batch is complimentary and appears next to your 'Add to Cart' and 'Checkout' buttons. Customers can choose either option. This prevents any conversion cannibalization. 
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    How does the checkout work?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Customers click on a 'Make an offer' button, choose 'Buy now' or select a price they want to pay, and then complete a checkout. They don't pay anything for offers unless you agree to the price.
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    How do I turn offers into new orders?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Offers you receive from customers will appear in your Shopify admin interface, where you can instantly turn offers into new unfulfilled orders in seconds.
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Are there any additional fees or charges?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    None whatsoever — the best Batch features are available for one flat monthly price. You can save 18% on your subscription by signing up for a yearly plan.
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    What is the cancellation policy?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    You can cancel your subscription anytime from the Shopify admin interface, there are no contracts. Simply subscribe again once you're ready to activate offers into revenue again. 
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Is there a free trial I can use to try Batch?
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Batch is totally free to install and your free account entitles you to receive as many customer offers as you wish before upgrading, which lets you turn offers into new revenue.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )
    }

    const renderTestimonial = () => {
      const testimonialPeople = ["alexis", "brie", "jayna", "maegan"]
      const testimonialPerson = testimonialPeople[Math.floor(Math.random()*testimonialPeople.length)]

      return <PrimaryTestimonial person={testimonialPerson} />
    }
    
    return (
      <>
        <Helmet>
          {/* Meta */}
            <html lang="en" />
            <title>{data.site.siteMetadata.pages.pricing.title}</title>
            <meta charSet="utf-8" />
            <meta name="title" content={`Features - ${data.site.siteMetadata.title}`}/>
            <meta name="description" content={data.site.siteMetadata.pages.pricing.description} /> 
            <link rel="canonical" href={data.site.siteMetadata.pages.pricing.url} />
        </Helmet> 
        <Layout container={false}>
          <div className="w-full bg-gray-900">
            <div className="pt-2 sm:pt-4 lg:pt-6">
              <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                  <h1 className="text-lg font-semibold leading-6 tracking-wider text-gray-300 uppercase">
                    Pricing
                  </h1>
                  <h2 className="text-4xl font-extrabold leading-tight text-white sm:text-4xl lg:text-5xl">
                    <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                        Pay nothing until we make you money
                    </span>
                  </h2>
                  
                  <p className="text-xl text-gray-300">
                    Install Batch to start collecting customer offers. Upgrade to customize checkout &amp; activate offers. 
                  </p>
                  <p className="px-8 pt-4">
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-800 text-indigo-100">
                    10x ROI Guaranteed
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 bg-gray-50 sm:mt-6 lg:mt-8">
              {/* Pricing Tables */}
              {renderPricingTables()}
              
              {/* ProductHunt Offer */}
              {renderProductHuntOffer()}
            
              {/* Benefits */}
              {renderBenefits()}
            
              {/* FAQ */}
              {renderFAQ()}
            
              {/* Testimonial */}
              {renderTestimonial()}
            </div>
          </div>
        </Layout>
      </>
    )
  }

export default PricingPage
